import { IHttpOptions, recordToParams } from 'nest-utilities-client';

type LegacyOptions = {
  offset?: number;
  limit?: number;
  sort?: string;
  populate?: string;
  pick?: string;
  search?: string;
  searchScope?: string;
  filter?: Record<string, string>;
};
export const migrateToLegacyOptions = (
  options: IHttpOptions,
): LegacyOptions => {
  const legacyOptions: LegacyOptions = {};
  if (options.offset) {
    legacyOptions.offset = options.offset;
  }
  if (options.skip) {
    legacyOptions.offset = options.skip;
  }
  if (options.limit) {
    legacyOptions.limit = options.limit;
  }
  if (options.select) {
    legacyOptions.pick = options.select.join(',');
  }
  if (options.populate) {
    legacyOptions.populate = options.populate.join(',');
  }
  if (options.sort) {
    legacyOptions.sort = options.sort.join(',');
  }
  recordToParams(options.match || {}).forEach((param) => {
    // disable certain mongodb operators from being used in the query
    if (
      ['$options', '$in', '$nin', '$gt', '$lt'].some((operator) =>
        param.includes(operator),
      )
    ) {
      return;
    }

    // extract the key and value from the param
    const paramParts = param.replace(/\]/g, '').split(/[[=]/g);
    const value = paramParts.pop() ?? '';
    const key =
      paramParts
        .filter((part) => part[0] !== '$' && Number.isNaN(Number(part)))
        .pop() ?? '';

    // if $regex is used, the param concerns a search query
    if (param.includes('$regex')) {
      legacyOptions.search = value;
      legacyOptions.searchScope = [legacyOptions.searchScope, key]
        .filter(Boolean)
        .join(',');
    } else {
      legacyOptions.filter = legacyOptions.filter ?? {};
      legacyOptions.filter[key] =
        (paramParts.includes('$ne') ? '!' : '') + value;
    }
  });

  return legacyOptions;
};
