import {
  Breadcrumbs,
  ConfirmModal,
  ControlBar,
  FlexElement,
  Heading,
  routable,
  useModalContext,
  useNotificationContext,
} from '@hulanbv/dashboard-components';
import { Role } from '@hulanbv/ramvrie-packages/enums';
import { IDeck } from '@hulanbv/ramvrie-packages/interfaces';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { routes } from '../../domain/common/constants/routes.constant';
import { deckService } from '../../domain/decks/deck.service';
import { DeckForm } from '../templates/forms/deck-form.template';
import { CardsScreen } from './cards.screen';
import { DecksScreen } from './decks.screen';
import { NotFoundScreen } from './not-found.screen';

export const DeckUpdateScreen = routable(
  {
    root: {
      path: (deckId = ':deckId') => `/decks/${deckId}/edit`,
      isAccessible: () => hasRole(Role.ADMIN, Role.MANAGER),
      name: () => 'Update deck',
    },
  },
  () => {
    const { openModal } = useModalContext();
    const { addNotification } = useNotificationContext();
    const { deckId } = useParams<'deckId'>();
    const navigate = useNavigate();
    const [deck, setDeck] = useState<IDeck>();

    if (!deckId) {
      return <Navigate to={NotFoundScreen.paths.root()} replace />;
    }

    useEffect(() => {
      deckService.get(deckId).then((response) => setDeck(response.data));
      // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
    }, []);

    const update = useCallback(
      async (data: FormData) => {
        await deckService.put(data);
        addNotification(() => 'Deck updated successfully', 'success');
        navigate(CardsScreen.paths.root(deckId));
      },
      [addNotification, navigate, deckId],
    );

    const deleteModel = useCallback(async () => {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModal
          resolve={resolve}
          title="Are you sure you want to delete this deck?"
          flavor="danger"
        />
      ));
      if (!isConfirmed) {
        return;
      }

      try {
        await deckService.delete(deckId);
        navigate(DecksScreen.paths.root());
        addNotification(() => 'Successfully deleted the deck', 'success');
      } catch {
        addNotification(
          () => 'Something went wrong while deleting the deck',
          'danger',
        );
      }
    }, [openModal, addNotification, deckId, navigate]);

    return (
      <FlexElement alignItems="stretch">
        <Breadcrumbs routes={routes} />
        {deck && (
          <DeckForm
            model={deck}
            onDelete={deleteModel}
            onSubmit={update}
            head={<ControlBar left={[<Heading>Update deck</Heading>]} />}
          />
        )}
      </FlexElement>
    );
  },
);
