import {
  Modal,
  ModalContext,
  NotificationCenter,
  NotificationContext,
  useModal,
  useNotification,
} from '@hulanbv/dashboard-components';
import { Fragment, FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthContext } from '../domain/authentication/auth.context';
import { useAuth } from '../domain/authentication/use-auth.hook';
import { App } from './templates/app.template';

export const Main: FunctionComponent = () => {
  const authHook = useAuth();
  const modalHook = useModal();
  const notificationHook = useNotification();

  return (
    <Fragment>
      <AuthContext.Provider value={authHook}>
        <NotificationContext.Provider value={notificationHook}>
          <ModalContext.Provider value={modalHook}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
            <Modal />
            <NotificationCenter />
          </ModalContext.Provider>
        </NotificationContext.Provider>
      </AuthContext.Provider>
    </Fragment>
  );
};
