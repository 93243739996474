import { AuthorizedHttpService } from '../common/http-service';
import { IResponse } from '../common/interfaces/response.interface';

interface IImageResponse {
  url: string;
  thumbUrl: string;
  iconUrl: string;
}
export class Service {
  http = new AuthorizedHttpService();

  uploadImage(file: File): Promise<IResponse<IImageResponse>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<IImageResponse>(
      [process.env.REACT_APP_API_URL, 'files', 'image'].join('/'),
      formData,
    );
  }
}

export const fileService = new Service();
