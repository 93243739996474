import { IResponse } from 'nest-utilities-client';
import { IDeck } from '@hulanbv/ramvrie-packages/interfaces';
import { CustomCrudService } from '../common/crud-service';
import { AuthorizedHttpService } from '../common/http-service';

class Service extends CustomCrudService<IDeck> {
  constructor() {
    super(
      [process.env.REACT_APP_API_URL, 'decks'].join('/'),
      new AuthorizedHttpService(),
    );
  }

  getLabels(): Promise<IResponse<string[]>> {
    return this.http.get<string[]>([this.controller, 'labels'].join('/'));
  }
}

export const deckService = new Service();
