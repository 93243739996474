import {
  Button,
  ControlledTable,
  ControlledTableProps,
  useNotificationContext,
} from '@hulanbv/dashboard-components';
import { IDeck } from '@hulanbv/ramvrie-packages/interfaces';
import { FC, useEffect, useRef, useState } from 'react';
import { style } from 'typestyle';
import { deckService } from '../../../domain/decks/deck.service';

export const DeckTable: FC<
  Omit<ControlledTableProps<IDeck>, 'service' | 'columns'>
> = (props) => {
  const { addNotification } = useNotificationContext();
  const tableActionsRef: ControlledTableProps<IDeck>['actionsRef'] =
    useRef(null);

  const [isFetching, setIsFetching] = useState(false);
  const [deckTotalCount, setDeckTotalCount] = useState(0);

  useEffect(() => {
    // For the up and down buttons we need to know how many decks are present.
    // When using getAll we get the full response object, including the x-total-count header.
    const getDecksTotalCount = async () => {
      const response = await deckService.getAll({ limit: 1 });
      setDeckTotalCount(+(response.headers?.get('x-total-count') ?? 0));
    };
    getDecksTotalCount();
  }, []);

  const moveDeck = async (selectedDeck: IDeck, moveUp: boolean) => {
    setIsFetching(true);
    try {
      // first find the deck we want to switch the selected deck with
      const { data: response } = await deckService.getAll({
        match: {
          sortOrder: moveUp
            ? selectedDeck.sortOrder - 1
            : selectedDeck.sortOrder + 1,
        },
      });

      // if no deck to switch with is found, throw an error
      if (!response[0]) {
        throw Error('Something went wrong switching the decks');
      }

      const switchDeck = response[0];

      // update both of the decks sortOrder
      await deckService.patch({
        _id: selectedDeck._id,
        sortOrder: switchDeck.sortOrder,
      });
      await deckService.patch({
        _id: switchDeck._id,
        sortOrder: selectedDeck.sortOrder,
      });

      // finally update the table so the sortOrder is updated
      await tableActionsRef.current?.refresh();
    } catch (error) {
      addNotification(() => {
        if (error instanceof Error) {
          return error.message;
        }
        return 'Something went wrong';
      }, 'danger');
    }
    // finally set is fetching to false so the user can click the button again
    setIsFetching(false);
  };

  return (
    <ControlledTable<IDeck>
      actionsRef={tableActionsRef}
      {...props}
      service={deckService}
      searchFields={['name']}
      sort={{
        defaultFields: ['sortOrder', 'name'],
        defaultDescending: false,
        fields: [
          ['Name', ['name']],
          ['Updated at', ['updatedAt']],
        ],
      }}
      columns={[
        ['Name', ({ name }) => name],
        ['Labels', ({ labels }) => labels?.join(', ') ?? ''],
        ['Visible in app', ({ isPublic }) => (isPublic === true ? 'Yes' : '')],
        [
          'Information block',
          ({ isDisabled }) => (isDisabled === true ? 'Yes' : ''),
        ],
        [
          'Sort actions',
          (deck) => (
            <>
              <Button
                attributes={{
                  className: styles.sortUpButton,
                  disabled:
                    deck.sortOrder === 0 || deckTotalCount === 1 || isFetching,
                  onClick: (event) => {
                    event.stopPropagation();
                    moveDeck(deck, true);
                  },
                }}
              >
                Up
              </Button>
              <Button
                attributes={{
                  disabled:
                    deckTotalCount - 1 === deck.sortOrder ||
                    deckTotalCount === 1 ||
                    isFetching,
                  onClick: (event) => {
                    event.stopPropagation();
                    moveDeck(deck, false);
                  },
                }}
              >
                Down
              </Button>
            </>
          ),
        ],
      ]}
    />
  );
};

const styles = {
  sortUpButton: style({
    marginRight: 10,
  }),
};
