import { routable } from '@hulanbv/dashboard-components';
import { Role } from '@hulanbv/ramvrie-packages/enums';
// eslint-disable-next-line import/named -- import needed
import { Navigate, Params } from 'react-router-dom';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { deckService } from '../../domain/decks/deck.service';
import { DecksScreen } from './decks.screen';

export const DeckDetailsScreen = routable(
  {
    root: {
      isAccessible: () => hasRole(Role.ADMIN),
      path: (deckId = ':deckId') => `/decks/${deckId}`,
      name: async (params: Params<'deckId'>) => {
        const { data } = await deckService.get(params.deckId ?? '');
        return data.name;
      },
    },
  },

  () => <Navigate replace to={DecksScreen.paths.root()} />,
);
