import {
  ControlledTable,
  ControlledTableProps,
} from '@hulanbv/dashboard-components';
import { ICard } from '@hulanbv/ramvrie-packages/interfaces';
import { FC } from 'react';
import { cardService } from '../../../domain/cards/card.service';

export const CardTable: FC<
  Omit<ControlledTableProps<ICard>, 'service' | 'columns'>
> = (props) => (
  <ControlledTable
    service={cardService}
    searchFields={['name', 'category']}
    sort={{
      defaultFields: ['category', 'order'],
      defaultDescending: false,
      fields: [
        ['Name', ['name']],
        ['Category', ['category']],
        ['Updated at', ['updatedAt']],
      ],
    }}
    columns={[
      ['Name', ({ name }) => name],
      ['Category', ({ category }) => category],
      ['Order', ({ order }) => order],
      [
        'Updated at',
        ({ updatedAt }) => new Date(updatedAt ?? '').toLocaleString(),
      ],
    ]}
    {...props}
  />
);
