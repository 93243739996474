import { ICard } from '@hulanbv/ramvrie-packages/interfaces';
import { CustomCrudService } from '../common/crud-service';
import { AuthorizedHttpService } from '../common/http-service';

class Service extends CustomCrudService<ICard> {
  constructor() {
    super(
      [process.env.REACT_APP_API_URL, 'cards'].join('/'),
      new AuthorizedHttpService(),
    );
  }
}

export const cardService = new Service();
