import { FC, ImgHTMLAttributes } from 'react';
import { classes, style } from 'typestyle';

interface IProps {
  attributes: ImgHTMLAttributes<HTMLImageElement>;
}

export const ImagePreview: FC<IProps> = (props) => (
  <img
    alt="preview"
    {...props.attributes}
    className={classes(styles.imagePreview, props.attributes.className)}
  />
);

const styles = {
  imagePreview: style({
    maxWidth: 100,
    maxHeight: 100,
  }),
};
