import {
  ICredentials,
  ISessionToken,
} from '@hulanbv/ramvrie-packages/interfaces';
import { useCallback, useState } from 'react';
import { IHttpOptions } from '../common/interfaces/http-options.interface';
import { IResponse } from '../common/interfaces/response.interface';
import { authenticationService } from './authentication.service';

export interface IUseAuth {
  login: (
    credentials: ICredentials | FormData,
    options?: IHttpOptions,
  ) => Promise<IResponse<ISessionToken>>;
  logout: () => Promise<void>;
  validate: (options?: IHttpOptions) => Promise<IResponse<ISessionToken>>;
  sessionToken: ISessionToken | null;
}
/**
 * Authenticates with the API returning a session token
 * @returns
 */
export const useAuth = (): IUseAuth => {
  const [sessionToken, setSessionToken] = useState<ISessionToken | null>(null);

  const login = useCallback(
    async (credentials: FormData | ICredentials, options?: IHttpOptions) => {
      const response = await authenticationService.login(credentials, options);
      setSessionToken(response.data);
      return response;
    },
    [],
  );

  const validate = useCallback(async (options?: IHttpOptions) => {
    try {
      const response = await authenticationService.validate(options);
      setSessionToken(response.data);

      return response;
    } catch (error) {
      setSessionToken(null);
      throw error;
    }
  }, []);

  const logout = useCallback(async () => {
    await authenticationService.logout();
    setSessionToken(null);
  }, []);

  return {
    login,
    logout,
    validate,
    sessionToken,
  };
};
