// eslint-disable-next-line import/named -- need named import for web-vitals
import { ReportHandler } from 'web-vitals';

export async function reportWebVitals(
  onPerfEntry?: ReportHandler,
): Promise<void> {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    const webVitals = await import('web-vitals');
    webVitals.getCLS(onPerfEntry);
    webVitals.getFID(onPerfEntry);
    webVitals.getFCP(onPerfEntry);
    webVitals.getLCP(onPerfEntry);
    webVitals.getTTFB(onPerfEntry);
  }
}
