import { faGamepad, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { LabeledIcon, Router, Sidebar } from '@hulanbv/dashboard-components';
import { FC, useEffect, useState } from 'react';
import { style } from 'typestyle';
import { useAuthContext } from '../../domain/authentication/use-auth-context.hook';
import { routes } from '../../domain/common/constants/routes.constant';
import { DecksScreen } from '../screens/decks.screen';
import { LogoutScreen } from '../screens/logout.screen';

const styles = {
  body: style({
    display: 'flex',
    minHeight: '100vh',
  }),
  content: style({
    flex: 1,
    padding: '50px 85px',
    background: '#F3F4F6',
  }),
  icon: style({
    marginRight: 15,
    fontSize: 18,
  }),
};

export const App: FC = () => {
  const { sessionToken, validate } = useAuthContext();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    validate().finally(() => setInitialized(true));
  }, [validate]);

  if (!initialized) {
    return <></>;
  }

  // unauthenticated components have no container styling
  if (!sessionToken) {
    return <Router routes={routes} />;
  }
  return (
    <div className={styles.body}>
      <Sidebar
        items={[
          {
            to: DecksScreen.paths.root(),
            label: <LabeledIcon icon={faGamepad} label={'Decks'} />,
          },
          {
            to: LogoutScreen.paths.root(),
            label: <LabeledIcon icon={faSignOutAlt} label={'Log out'} />,
          },
        ]}
      />
      <div className={styles.content}>
        <Router routes={routes} />
      </div>
    </div>
  );
};
