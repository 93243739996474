import {
  Card,
  Checkbox,
  Content,
  ExplainedSection,
  HiddenValue,
  Input,
  StyledFormProps,
  StyledForm,
  TextArea,
  LabeledContent,
  FlexElement,
  Autocomplete,
} from '@hulanbv/dashboard-components';
import { IDeck } from '@hulanbv/ramvrie-packages/interfaces';

import { FC, useEffect, useState } from 'react';
import { fileService } from '../../../domain/file/file.service';
import { ImagePreview } from '../../elements/image-preview.element';
import { deckService } from '../../../domain/decks/deck.service';

export const DeckForm: FC<StyledFormProps<IDeck>> = (props) => {
  const [selectedBoxUrl, setSelectedBoxUrl] = useState<string>();
  const [selectedCardBackUrl, setSelectedCardBackUrl] = useState<string>();
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    const getLabels = async () => {
      const { data: labels } = await deckService.getLabels();
      setLabels(labels);
    };
    getLabels();
  }, []);

  return (
    <StyledForm
      {...{
        ...props,
      }}
    >
      <HiddenValue name="_id" value={props.model?._id} />
      <HiddenValue
        name="boxImagePath"
        value={selectedBoxUrl || props.model?.boxImagePath}
      />

      <HiddenValue
        name="cardBackImagePath"
        value={selectedCardBackUrl || props.model?.cardBackImagePath}
      />

      <ExplainedSection title="Main info">
        <Card>
          <Content>
            <FlexElement alignItems="start" gap={25}>
              <LabeledContent label="Name">
                <Input
                  attributes={{
                    defaultValue: props.model?.name,
                    name: 'name',
                    placeholder: 'Enter a name',
                    required: true,
                  }}
                />
              </LabeledContent>

              <LabeledContent label="Description">
                <TextArea
                  attributes={{
                    defaultValue: props.model?.description,
                    name: 'description',
                    placeholder: 'Enter a description',
                    required: true,
                  }}
                />
              </LabeledContent>
              <LabeledContent label="Labels">
                <Autocomplete
                  options={labels}
                  name="labels"
                  defaultValue={props.model?.labels}
                  placeholder="Labels"
                  multiselect
                />
              </LabeledContent>
              <FlexElement attributes={{ style: { width: 'initial' } }}>
                <Checkbox
                  defaultValue={props.model?.isPublic}
                  name={'isPublic'}
                >
                  This deck is visible in the app
                </Checkbox>
              </FlexElement>
              <FlexElement attributes={{ style: { width: 'initial' } }}>
                <Checkbox
                  defaultValue={props.model?.isDisabled}
                  name={'isDisabled'}
                >
                  This deck is an information block
                </Checkbox>
              </FlexElement>
            </FlexElement>
          </Content>
        </Card>
      </ExplainedSection>
      <ExplainedSection title="Images">
        <Card>
          <Content>
            <FlexElement>
              <LabeledContent label="Box image">
                {(selectedBoxUrl || props.model?.boxImagePath) && (
                  <ImagePreview
                    attributes={{
                      src: selectedBoxUrl || props.model?.boxImagePath,
                    }}
                  />
                )}
                <Input
                  attributes={{
                    type: 'file',
                    accept: 'image/*',
                    required: !props.model?.boxImagePath,
                    onChange: async (evt) => {
                      const file = evt.currentTarget.files?.[0];
                      if (file) {
                        const { data } = await fileService.uploadImage(file);
                        setSelectedBoxUrl(data.url);
                      }
                    },
                  }}
                ></Input>
              </LabeledContent>

              <LabeledContent label="Card back image">
                {(selectedCardBackUrl || props.model?.cardBackImagePath) && (
                  <ImagePreview
                    attributes={{
                      src:
                        selectedCardBackUrl || props.model?.cardBackImagePath,
                    }}
                  />
                )}
                <Input
                  attributes={{
                    type: 'file',
                    accept: 'image/*',
                    onChange: async (evt) => {
                      const file = evt.currentTarget.files?.[0];
                      if (file) {
                        const { data } = await fileService.uploadImage(file);
                        setSelectedCardBackUrl(data.url);
                      }
                    },
                  }}
                ></Input>
              </LabeledContent>
            </FlexElement>
          </Content>
        </Card>
      </ExplainedSection>
    </StyledForm>
  );
};
