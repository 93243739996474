import {
  HttpService,
  IHttpOptions,
  IResponse,
  recordToParams,
} from 'nest-utilities-client';
import { authenticationService } from '../authentication/authentication.service';
import { migrateToLegacyOptions } from './utilities/migrate-to-legacy-options.utils';

export class AuthorizedHttpService extends HttpService {
  getHeaders(
    url: string,
  ): Record<string, string> | Promise<Record<string, string>> {
    return {
      Authorization: authenticationService.getSession()?.token ?? '',
    };
  }

  onRequestError(error: IResponse<unknown>): void | Promise<void> {
    if (error.status === 401) {
      authenticationService.logout();
    }
  }

  /**
   * Execute a fetch request
   * @param method
   * @param url
   * @param body
   * @param options
   */
  async fetch<ResponseType = unknown>(
    method: string,
    url: string,
    // eslint-disable-next-line no-undef -- eslint TS doesn't know about BodyInit
    body: BodyInit,
    options?: IHttpOptions,
  ): Promise<IResponse<ResponseType>> {
    // eslint-disable-next-line no-undef -- eslint TS doesn't know about RequestInit
    const init: RequestInit = {
      method,
      body,
      headers: {},
    };

    // convert the httpOptions to query parameters
    const queryParams = recordToParams(
      migrateToLegacyOptions(options || {}),
    ).join('&');

    // recordToParams returns encoded string in some cases, so we need to decode it
    const requestUrl = decodeURIComponent([url, queryParams].join('?'));

    // set the base headers
    init.headers = await this.getHeaders(requestUrl);

    // clear GET requests and empty bodies from body data since fetch will error
    if (method === 'GET' || !init.body) {
      delete init.body;
    }

    // define the type of request
    if (
      typeof init.body === 'object' &&
      init.body instanceof FormData === false
    ) {
      init.headers['Content-Type'] = 'application/json';
      init.body = JSON.stringify(init.body);
    }

    // construct and execute the request
    try {
      const response: IResponse<ResponseType> = (await fetch(
        requestUrl,
        init,
      )) as IResponse<ResponseType>;

      // store the actual outcome in response.data
      const dataString = await response.text();
      try {
        response.data = JSON.parse(dataString);
      } catch {
        // catch empty and string responses
        response.data = (dataString || undefined) as unknown as ResponseType;
      }

      // forward error responses
      if (response.status >= 400) {
        throw response;
      }

      return response;
    } catch (error) {
      await this.onRequestError(error as unknown as IResponse<unknown>);

      throw error;
    }
  }
}
