import {
  Anchor,
  Breadcrumbs,
  Button,
  Card,
  Content,
  ControlBar,
  FlexElement,
  Heading,
  routable,
} from '@hulanbv/dashboard-components';
import { Role } from '@hulanbv/ramvrie-packages/enums';
import { IDeck } from '@hulanbv/ramvrie-packages/interfaces';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { routes } from '../../domain/common/constants/routes.constant';
import { deckService } from '../../domain/decks/deck.service';
import { CardTable } from '../templates/tables/card-table.template';
import { CardCreateScreen } from './card-create.screen';
import { CardUpdateScreen } from './card-update.screen';
import { NotFoundScreen } from './not-found.screen';
import { DeckUpdateScreen } from './deck-update.screen';

export const CardsScreen = routable(
  {
    root: {
      path: (deckId = ':deckId') => `/decks/${deckId}/cards`,
      isAccessible: () => hasRole(Role.ADMIN, Role.MANAGER),
      name: () => 'Cards',
    },
  },
  () => {
    const params = useParams<'deckId'>();
    const navigate = useNavigate();
    const { deckId } = params;
    const [deck, setDeck] = useState<IDeck>();

    if (!deckId) {
      return <Navigate to={NotFoundScreen.paths.root()} replace />;
    }

    useEffect(() => {
      deckService.get(deckId).then((response) => setDeck(response.data));
      // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
    }, []);

    return (
      <FlexElement alignItems="stretch">
        <Breadcrumbs routes={routes} />
        <ControlBar
          left={[<Heading>{deck && deck?.name} cards</Heading>]}
          right={[
            <Anchor to={DeckUpdateScreen.paths.root(deckId)}>
              <Button>Details</Button>
            </Anchor>,
            <Anchor to={CardCreateScreen.paths.root(deckId)}>
              <Button flavor="primary">Add card</Button>
            </Anchor>,
          ]}
        />
        <Card>
          <Content>
            <CardTable
              options={{ match: { deckId } }}
              onRowClick={(card) =>
                navigate(CardUpdateScreen.paths.root(deckId, card._id))
              }
            />
          </Content>
        </Card>
      </FlexElement>
    );
  },
);
