import { ICredentials } from '@hulanbv/ramvrie-packages/interfaces';
import { createContext } from 'react';
import { IHttpOptions } from '../common/interfaces/http-options.interface';
import { authenticationService } from './authentication.service';
import { useAuth } from './use-auth.hook';

export const AuthContext = createContext<ReturnType<typeof useAuth>>({
  login: (credentials: ICredentials | FormData, options?: IHttpOptions) =>
    authenticationService.login(credentials, options),
  logout: () => authenticationService.logout(),
  validate: (options?: IHttpOptions) => authenticationService.validate(options),
  sessionToken: null,
});
