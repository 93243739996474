import {
  Breadcrumbs,
  ControlBar,
  FlexElement,
  Heading,
  routable,
  useNotificationContext,
} from '@hulanbv/dashboard-components';
import { Role } from '@hulanbv/ramvrie-packages/enums';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { routes } from '../../domain/common/constants/routes.constant';
import { deckService } from '../../domain/decks/deck.service';
import { DeckForm } from '../templates/forms/deck-form.template';
import { DecksScreen } from './decks.screen';

export const DeckCreateScreen = routable(
  {
    root: {
      path: () => '/decks/create',
      isAccessible: () => hasRole(Role.ADMIN, Role.MANAGER),
      name: () => 'Create deck',
    },
  },
  () => {
    const { addNotification } = useNotificationContext();
    const navigate = useNavigate();

    const submit = useCallback(
      async (formData) => {
        try {
          await deckService.post(formData);
          navigate(DecksScreen.paths.root(), {
            replace: true,
          });

          addNotification(() => 'Successfully created the deck', 'success');
        } catch {
          addNotification(
            () => 'Something went wrong while creating the deck',
            'danger',
          );
        }
      },
      [addNotification, navigate],
    );

    return (
      <FlexElement alignItems="stretch">
        <Breadcrumbs routes={routes} />
        <ControlBar left={[<Heading>Create deck</Heading>]} />
        <DeckForm onSubmit={submit} />
      </FlexElement>
    );
  },
);
