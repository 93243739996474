import {
  Button,
  FlexElement,
  Form,
  FormProps,
  Input,
  LabeledContent,
} from '@hulanbv/dashboard-components';
import { FC } from 'react';
import { ICredentials } from '@hulanbv/ramvrie-packages/interfaces';

export const LoginForm: FC<FormProps<ICredentials>> = (props) => (
  <Form {...{ ...props }}>
    <FlexElement>
      <LabeledContent label="Email address">
        <Input
          attributes={{
            name: 'email',
            placeholder: 'Email',
          }}
        />
      </LabeledContent>
      <LabeledContent label="Password">
        <Input
          attributes={{
            name: 'password',
            type: 'password',
            placeholder: 'Password',
          }}
        />
      </LabeledContent>
      <Button attributes={{ type: 'submit' }} flavor={'primary'} wide>
        Sign in
      </Button>
    </FlexElement>
  </Form>
);
