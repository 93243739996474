import { getRoutesFromRoutables } from '@hulanbv/dashboard-components';
import { CardCreateScreen } from '../../../components/screens/card-create.screen';
import { CardUpdateScreen } from '../../../components/screens/card-update.screen';
import { CardsScreen } from '../../../components/screens/cards.screen';
import { DeckCreateScreen } from '../../../components/screens/deck-create.screen';
import { DeckDetailsScreen } from '../../../components/screens/deck-details.screen';
import { DeckUpdateScreen } from '../../../components/screens/deck-update.screen';
import { DecksScreen } from '../../../components/screens/decks.screen';
import { LoginScreen } from '../../../components/screens/login.screen';
import { LogoutScreen } from '../../../components/screens/logout.screen';
import { NotFoundScreen } from '../../../components/screens/not-found.screen';

export const routes = getRoutesFromRoutables([
  LoginScreen,
  LogoutScreen,
  NotFoundScreen,
  DecksScreen,
  DeckDetailsScreen,
  DeckCreateScreen,
  DeckUpdateScreen,
  CardsScreen,
  CardCreateScreen,
  CardUpdateScreen,
]);
