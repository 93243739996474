import {
  Breadcrumbs,
  FlexElement,
  routable,
  useNotificationContext,
} from '@hulanbv/dashboard-components';
import { Role } from '@hulanbv/ramvrie-packages/enums';
import { useCallback } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { cardService } from '../../domain/cards/card.service';
import { routes } from '../../domain/common/constants/routes.constant';
import { CardForm } from '../templates/forms/card-form.template';
import { CardsScreen } from './cards.screen';
import { NotFoundScreen } from './not-found.screen';

export const CardCreateScreen = routable(
  {
    root: {
      path: (deckId = ':deckId') => `/decks/${deckId}/cards/create`,
      isAccessible: () => hasRole(Role.ADMIN, Role.MANAGER),
      name: () => 'Create card',
    },
  },
  () => {
    const params = useParams<'deckId'>();
    const { deckId } = params;
    if (!deckId) {
      return <Navigate to={NotFoundScreen.paths.root()} replace />;
    }

    const { addNotification } = useNotificationContext();
    const navigate = useNavigate();

    const submit = useCallback(
      async (formData: FormData) => {
        try {
          await cardService.post(formData);
          navigate(CardsScreen.paths.root(deckId), {
            replace: true,
          });

          addNotification(() => 'Successfully created the deck', 'success');
        } catch {
          addNotification(
            () => 'Something went wrong while creating the deck',
            'danger',
          );
        }
      },
      [addNotification, navigate, deckId],
    );

    return (
      <FlexElement alignItems="stretch">
        <Breadcrumbs routes={routes} />
        <CardForm onSubmit={submit} deckId={deckId} />
      </FlexElement>
    );
  },
);
