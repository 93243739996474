import {
  Anchor,
  Breadcrumbs,
  Button,
  Card,
  Content,
  ControlBar,
  FlexElement,
  Heading,
  routable,
} from '@hulanbv/dashboard-components';
import { Role } from '@hulanbv/ramvrie-packages/enums';
import { IDeck } from '@hulanbv/ramvrie-packages/interfaces';
import { useNavigate } from 'react-router-dom';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { routes } from '../../domain/common/constants/routes.constant';
import { DeckTable } from '../templates/tables/deck-table.template';
import { CardsScreen } from './cards.screen';
import { DeckCreateScreen } from './deck-create.screen';

export const DecksScreen = routable(
  {
    root: {
      path: () => '/decks',
      isAccessible: () => hasRole(Role.ADMIN, Role.MANAGER),
      name: () => 'Decks',
    },
  },
  () => {
    const navigate = useNavigate();

    return (
      <FlexElement alignItems="stretch">
        <Breadcrumbs routes={routes} />
        <ControlBar
          left={[<Heading>Decks</Heading>]}
          right={[
            <Anchor to={DeckCreateScreen.paths.root()}>
              <Button flavor="primary">Add deck</Button>
            </Anchor>,
          ]}
        />
        <Card>
          <Content>
            <DeckTable
              onRowClick={(deck: IDeck) =>
                navigate(CardsScreen.paths.root(deck._id))
              }
            />
          </Content>
        </Card>
      </FlexElement>
    );
  },
);
