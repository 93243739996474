import { ControlError, Heading, routable } from '@hulanbv/dashboard-components';
import { useCallback, useState } from 'react';
import { style } from 'typestyle';
import background from '../../assets/images/background.jpeg';
import { useAuthContext } from '../../domain/authentication/use-auth-context.hook';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { LoginForm } from '../templates/forms/login-form.template';

export const LoginScreen = routable(
  {
    root: {
      path: () => '/',
      isAccessible: () => !hasRole(),
      name: () => 'Log in',
    },
  },
  () => {
    const { login } = useAuthContext();
    const [hasError, setHasError] = useState(false);

    const submit = useCallback(
      async (formData: FormData) => {
        setHasError(false);
        try {
          await login(formData);
        } catch {
          setHasError(true);
        }
      },
      [login],
    );

    return (
      <div className={styles.loginScreen}>
        <div className={styles.image} />
        <div className={styles.content}>
          <Heading>Sign in to your account</Heading>
          {hasError && (
            <ControlError>Invalid username/password combination</ControlError>
          )}
          <LoginForm onSubmit={submit} />
        </div>
      </div>
    );
  },
);

const styles = {
  loginScreen: style({
    display: 'flex',
    minHeight: '100vh',
  }),
  image: style({
    flex: 1,
    background: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }),
  content: style({
    width: 580,
    padding: '150px 100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 15,
    justifyContent: 'center',
  }),
};
