import { routable } from '@hulanbv/dashboard-components';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../domain/authentication/use-auth-context.hook';
import { DecksScreen } from './decks.screen';
import { LoginScreen } from './login.screen';

export const NotFoundScreen = routable(
  {
    root: {
      path: () => '*',
      name: () => 'Not found',
    },
  },

  () => {
    const { sessionToken } = useAuthContext();

    return (
      <Navigate
        replace
        to={sessionToken ? DecksScreen.paths.root() : LoginScreen.paths.root()}
      />
    );
  },
);
