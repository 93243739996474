import { routable } from '@hulanbv/dashboard-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../domain/authentication/use-auth-context.hook';
import { hasRole } from '../../domain/authentication/utilities/has-role.utility';
import { NotFoundScreen } from './not-found.screen';

export const LogoutScreen = routable(
  {
    root: {
      isAccessible: () => hasRole(),
      path: () => '/logout',
      name: () => 'Log out',
    },
  },

  () => {
    const { logout } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
      logout().finally(() => navigate(NotFoundScreen.paths.root()));
    }, [navigate, logout]);

    return <></>;
  },
);
