import {
  ICredentials,
  ISessionToken,
} from '@hulanbv/ramvrie-packages/interfaces';
import { CustomCrudService } from '../common/crud-service';
import { AuthorizedHttpService } from '../common/http-service';
import { IHttpOptions } from '../common/interfaces/http-options.interface';
import { IResponse } from '../common/interfaces/response.interface';

class Service extends CustomCrudService<ISessionToken> {
  private sessionStorageKey = 'session';

  constructor() {
    super(
      [process.env.REACT_APP_API_URL, 'authentication'].join('/'),
      new AuthorizedHttpService(),
    );
  }

  /**
   * Create an authenticated session
   * @param credentials FormData object with `username` and `password`
   */
  async login(
    credentials: ICredentials | FormData,
    options?: IHttpOptions,
  ): Promise<IResponse<ISessionToken>> {
    const response = await this.http.post<ICredentials | ISessionToken>(
      this.controller,
      credentials,
      {
        ...options,
        populate: ['user', ...(options?.populate ?? [])],
      },
    );

    this.storeSession(response.data as ISessionToken);

    return response as IResponse<ISessionToken>;
  }

  /**
   * Disable the current authorization token
   * @param options
   * @returns
   */
  async logout(): Promise<void> {
    await this.http.post([this.controller, 'logout'].join('/'), null);
    this.storeSession(null);
  }

  /**
   * Validate the currently used authorization token
   * @param options
   * @returns
   */
  async validate(options?: IHttpOptions): Promise<IResponse<ISessionToken>> {
    try {
      const response = await this.http.post<ISessionToken>(
        [this.controller, 'validate'].join('/'),
        {},
        {
          ...options,
          populate: ['user', ...(options?.populate ?? [])],
        },
      );
      this.storeSession(response.data);

      return response;
    } catch (err) {
      this.storeSession(null);
      throw err;
    }
  }

  /**
   * Store the session token in the localstorage
   * @param session
   */
  storeSession(session: ISessionToken | null) {
    if (session) {
      localStorage.setItem(this.sessionStorageKey, JSON.stringify(session));
    } else {
      localStorage.removeItem(this.sessionStorageKey);
    }
  }

  /**
   * Retrieve the session token from the local storage
   * @returns
   */
  getSession(): ISessionToken | null {
    // attempt to parse the stored string to a JSON object
    try {
      return (
        JSON.parse(localStorage.getItem(this.sessionStorageKey) ?? '') || null
      );
    } catch {
      return null;
    }
  }
}

export const authenticationService = new Service();
