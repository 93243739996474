import {
  Autocomplete,
  Card,
  Content,
  ExplainedSection,
  HiddenValue,
  Input,
  StyledFormProps,
  StyledForm,
  LabeledContent,
  FlexElement,
} from '@hulanbv/dashboard-components';
import { ICard } from '@hulanbv/ramvrie-packages/interfaces';

import { FC, useEffect, useState } from 'react';
import { cardService } from '../../../domain/cards/card.service';
import { fileService } from '../../../domain/file/file.service';
import { ImagePreview } from '../../elements/image-preview.element';

export const CardForm: FC<StyledFormProps<ICard> & { deckId: string }> = (
  props,
) => {
  const [selectedImagePath, setSelectedImagePath] = useState<string>();
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    // get al the cards for current deck and generate unique string array
    // of categories in this deck
    cardService
      .getAll({
        filter: { deckId: props.deckId },
      })
      .then((cards) => {
        setCategories(
          Array.from(new Set(cards.data.map((card) => card.category))),
        );
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only use on mount
  }, []);

  return (
    <StyledForm
      {...{
        ...props,
        title: props.model?._id ? 'Update card' : 'Add card',
      }}
    >
      <HiddenValue name="_id" value={props.model?._id} />
      <HiddenValue
        name="imagePath"
        value={selectedImagePath || props.model?.imagePath}
      />
      <HiddenValue name="deckId" value={props.deckId} />

      <ExplainedSection title="Main info">
        <Card>
          <Content>
            <FlexElement>
              <LabeledContent label="Name">
                <Input
                  attributes={{
                    defaultValue: props.model?.name,
                    name: 'name',
                    placeholder: 'Enter a name',
                  }}
                />
              </LabeledContent>
              <LabeledContent label="Category">
                <Autocomplete
                  options={categories}
                  name="category"
                  defaultValue={props.model?.category}
                  multiselect={false}
                />
              </LabeledContent>
              <LabeledContent label="Order">
                <Input
                  attributes={{
                    type: 'number',
                    defaultValue: props.model?.order,
                    name: 'order',
                    min: 0,
                    max: 3,
                    placeholder: 'Enter the order of this card (0-3)',
                  }}
                />
              </LabeledContent>
              <LabeledContent label="Box image">
                {(selectedImagePath || props.model?.imagePath) && (
                  <ImagePreview
                    attributes={{
                      src: selectedImagePath || props.model?.imagePath,
                    }}
                  />
                )}
                <Input
                  attributes={{
                    type: 'file',
                    accept: 'image/*',
                    required: !props.model?.imagePath,
                    onChange: async (evt) => {
                      const file = evt.currentTarget.files?.[0];
                      if (file) {
                        const { data } = await fileService.uploadImage(file);
                        setSelectedImagePath(data.url);
                      }
                    },
                  }}
                ></Input>
              </LabeledContent>
            </FlexElement>
          </Content>
        </Card>
      </ExplainedSection>
    </StyledForm>
  );
};
